import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Slide,
  Switch,
  TextField,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import {
  ModalManagerProps,
  withModalManager,
} from 'components/modal-manager';
import { CUSTOM_CLASSES } from 'core/theme';
import React, { useCallback } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { statesList } from 'constants/states';
import { timeZones } from 'constants/timeZones';
import { createPartner } from 'core/redux/thunks/partners/createParter';
import { useAppDispatch } from 'core/redux';
import { DEFAULT_LIST_ID } from 'constants/redux';
import * as yup from 'yup';
import { apiClient } from 'core/api';
import { FIELD_PRESETS } from 'constants/presets';
import { NO, StringBoolean, YES } from 'constants/stringBooleans';

const validationSchema = yup.object({
  id: yup
    .number()
    .required('This field is required')
    .test(
      'isDuplicate',
      'Partner with this ID already exists.',
      async (id) => {
        try {
          const result = await apiClient.getPartners({
            filter: {
              ovpid: id,
            },
            fieldPresets: [FIELD_PRESETS.PARTNER_WITH_PARTNER_GROUPS],
          });
          const {
            data: { data },
          } = result;

          return !data.length;
        } catch (error) {
          console.error(error);
          return true;
        }
      },
    ),
  businessName: yup.string().required('This field is required'),
  address: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zip: yup.string(),
  timeZone: yup.string(),
  websiteUrl: yup.string(),
  zendeskDomain: yup.string(),
});

interface FormValues {
  id: string;
  businessName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  timeZone: string;
  websiteUrl: string;
  zendeskDomain: string;
  currentPartner: StringBoolean;
}

const CreatePartnerDialog: React.FC<ModalManagerProps> = ({
  isOpen,
  close,
  clear,
}) => {
  const onClose = useCallback(() => close(), [close]);
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await dispatch(createPartner(values, DEFAULT_LIST_ID));
      close();
    },
    [close, dispatch],
  );
  return (
    <Dialog
      className={CUSTOM_CLASSES.DRAWER}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      transitionDuration={400}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'left',
          onExited: clear,
          mountOnEnter: true,
          unmountOnExit: true,
        } as TransitionProps
      }
    >
      <DialogTitle>
        Create partner
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <Formik<FormValues>
        initialValues={{
          id: '',
          businessName: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          timeZone: '',
          websiteUrl: '',
          zendeskDomain: '',
          currentPartner: YES,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isValidating, isSubmitting }) => (
          <>
            <DialogContent>
              <Form
                id="create-partner-form"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: theme.spacing(2),
                  padding: `${theme.spacing(2)} 0`,
                }}
                role="form"
              >
                <Field name="id">
                  {({ meta, field }: FieldProps<string>) => (
                    <TextField
                      label="ID"
                      {...field}
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.error ? meta.error : undefined}
                    />
                  )}
                </Field>
                <Field name="businessName">
                  {({ meta, field }: FieldProps<string>) => (
                    <TextField
                      label="Business Name"
                      {...field}
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.error ? meta.error : undefined}
                    />
                  )}
                </Field>
                <Field name="address">
                  {({ meta, field }: FieldProps<string>) => (
                    <TextField
                      label="Street Address"
                      {...field}
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.error ? meta.error : undefined}
                    />
                  )}
                </Field>
                <Field name="city">
                  {({ meta, field }: FieldProps<string>) => (
                    <TextField
                      label="City"
                      {...field}
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.error ? meta.error : undefined}
                    />
                  )}
                </Field>
                <Field name="state">
                  {({
                    meta,
                    field: { onBlur, ...field },
                    form,
                  }: FieldProps<string>) => (
                    <Autocomplete
                      id={field.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State/Province"
                          error={Boolean(meta.touched && meta.error)}
                          helperText={meta.error ? meta.error : undefined}
                        />
                      )}
                      options={statesList}
                      getOptionLabel={(option) => option?.text || ''}
                      isOptionEqualToValue={(option) =>
                        option.value === field.value
                      }
                      onChange={(_, newValue) => {
                        form.setFieldValue(
                          field.name,
                          newValue?.value || '',
                        );
                      }}
                      onBlur={onBlur}
                    />
                  )}
                </Field>
                <Field name="zip">
                  {({ meta, field }: FieldProps<string>) => (
                    <TextField
                      label="ZIP"
                      {...field}
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.error ? meta.error : undefined}
                    />
                  )}
                </Field>
                <Field name="timeZone">
                  {({
                    meta,
                    field: { onBlur, ...field },
                    form,
                  }: FieldProps<string>) => (
                    <Autocomplete
                      id={field.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Time Zone"
                          error={Boolean(meta.touched && meta.error)}
                          helperText={meta.error ? meta.error : undefined}
                        />
                      )}
                      options={timeZones}
                      getOptionLabel={(option) => option || ''}
                      isOptionEqualToValue={(option) =>
                        option === field.value
                      }
                      onChange={(_, newValue) => {
                        form.setFieldValue(field.name, newValue || '');
                      }}
                      onBlur={onBlur}
                    />
                  )}
                </Field>
                <Field name="websiteUrl">
                  {({ meta, field }: FieldProps<string>) => (
                    <TextField
                      label="Website URL"
                      {...field}
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.error ? meta.error : undefined}
                    />
                  )}
                </Field>
                <Field name="zendeskDomain">
                  {({ meta, field }: FieldProps<string>) => (
                    <TextField
                      label="Zendesk Domain"
                      {...field}
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.error ? meta.error : undefined}
                    />
                  )}
                </Field>
                <Field name="currentPartner">
                  {({
                    field,
                    form,
                  }: FieldProps<FormValues['currentPartner']>) => (
                    <>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              name={field.name}
                              checked={field.value === YES}
                              onChange={(_, checked) =>
                                form.setFieldValue(
                                  field.name,
                                  checked ? YES : NO,
                                )
                              }
                            />
                          }
                          label="Current Partner"
                          labelPlacement="start"
                        />
                      </FormGroup>
                      {field.value === NO && (
                        <FormHelperText>
                          Non-current partners will not be displayed on the
                          grid
                        </FormHelperText>
                      )}
                    </>
                  )}
                </Field>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                variant="contained"
                color="primary"
                data-cy="submit-create-group-dialog"
                type="submit"
                form="create-partner-form"
                disabled={isSubmitting || isValidating}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default withModalManager()(CreatePartnerDialog);
